import React, { Children, FC } from 'react';
import { checkedCircleIcon, removeCircleIcon, warningCircleIcon } from '../../images/svgIcons/index';
import './styles.scss';

const Message:FC<{type: string, text?: any, Children?: any}> = ({type, text}) => {
  return (
    <div className={`message-block ${type}`}>
      <span className="icon">{type === 'error' ? removeCircleIcon : type === 'success' ? checkedCircleIcon : warningCircleIcon}</span>
      {Children ? <span className="text">{text}</span> : Children}
    </div>
  )
}

export default Message;